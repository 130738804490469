<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>

        <v-row justify="start mt-10">
            <v-col
                    cols="2"
                    v-for="title in titles"
                    v-bind:key="title.link"
                    class="viewCard"
            >
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <router-link :to="title.link">
                            <HoverCard :cardData="title"></HoverCard>
                        </router-link>
                    </template>
                </v-tooltip>
            </v-col>
        </v-row>


    </v-content>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'
    export default {
        name: "Dashboard",
        watch: {},
        components: { HoverCard },
        computed: {
            items() {
                return [
                    {
                        text: this.$t('settings'),
                        disabled: true,
                        to: "/Hotel",
                        exact: true
                    }
                ]
            },
            titles() {
                return [
                    {
                        name: this.$t('ticket_category'),
                        link: "/MuseumService",
                        icon: "mdi-ticket-confirmation"
                    }
                ]
            }
        },
        data() {
            return {
                branchInformation: []

            }
        },
        mounted() {
            this.formData()
        },
        methods: {
            formData() {

            },
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>